<template>
  <div :key="updateKey">
    <ul class="flix-nav flix-nav-tabs flix-html-ul" v-if="Object.keys(getDays()).length > 1">
      <li v-for="(weekday, weekdaynr) in getDays()" :key="weekdaynr + Math.random(0,1)" class="flix-html-li" :class="{'flix-active': weekdaynr == currentWeekday}">
        <a href="#" class="flix-html-a" @click.prevent="function() { currentWeekday = weekdaynr; cloneSetting = [] }">{{ $t('constants.weekdays')[weekdaynr] }}</a>
      </li>
    </ul>
    <div v-for="(weekday, weekdaynr) in getDays()" :key="weekdaynr" class="flix-form-group">
      <transition name="flixFadeIn">
      <div v-if="weekdaynr == currentWeekday">
        <h3 class="flix-html-h3">{{ $t('constants.weekdays')[weekdaynr] }}</h3>
        <div class="flix-form-group">
          <div class="flix-input-group-2">
          <label class="flix-html-label">{{ $t('message.maxApplicants') }}</label>
          <select class="flix-form-control flix-form-group"  v-model="max_applicants[weekdaynr]">
            <option v-for="index in Array(51).keys()" v-show="index" :key="index" :value="index">{{ index }} {{ $tc('message.customer', index) }}</option>
          </select>
          </div>
          </div>
          <div class="flix-form-group">
            <label class="flix-html-label">{{ $t('message.maxAppointmentLength') }}</label>
          <select class="flix-form-control flix-form-group" :key="pickedTime[weekdaynr]" v-bind:value="pickedTime[weekdaynr]" @change="function(value) { setPickedTime(weekdaynr, value) }">
            <option v-for="(value, index) in selectTime" :key="index" :value="index">{{ value.title }}</option>
          </select>
          </div>
          <div class="flix-form-group">
          <div :key="updatePickedTime + 1">
            <div class="flix-input-group-2">
              <select class="flix-form-control flix-form-group" @change="setMarqueeKey" v-model="weekday[2]" v-if="pickedTime[weekdaynr] === 0">
                <optgroup :label="$t('message.add', {name: $tc('message.appointmentDuration', 1)})">
                  <option v-for="(label, index) in select.time" v-show="index" :key="index" :value="index * 1">{{ label }}</option>
                </optgroup>
              </select>
            </div>
            </div>
            <div class="flix-form-group">
            <select class="flix-form-control flix-form-group" @change="function(v){ setChangeAppointmentLength(v); setMarqueeKey() }" v-if="$store.getters.services.length && select.services && Object.keys(select.services).length && pickedTime[weekdaynr] === 1" v-model="weekday[2]" :key="$store.getters.serviceKey + $store.getters.services.length">
                <optgroup :label="$t('message.add', {name: $tc('message.service', 1)})">
                  <option v-for="(label) in select.services" :key="label" :value="label">{{ label }}</option>
                </optgroup>
            </select>
          </div>
          </div>
      <div>
        <div class="flix-form-group">
          <div class="flix-input-group-2">
            <label class="flix-html-label">{{ $t('message.nextAppointment') }}</label>
            <select class="flix-form-control flix-form-group" @change="setMarqueeKey" v-model="next_appointments[weekdaynr]">
                <option v-if="checkLastAppointment(weekday[2])" :value="weekday[2]">{{ $t('message.afterLastAppointment') }}</option>
                <option value="60">{{ $t('message.nextFullHour') }}</option>
                <option value="30">{{ $t('message.nextHalfHour') }}</option>
                <option value="15">{{ $t('message.nextQuaterHour') }}</option>
            </select>
          </div>
        </div>
        <div class="flix-form-group" :key="marqueeKey">
          <appointmentMarquee :pause="getPause(weekdaynr)" :weekday="weekday" :nextAppointments="next_appointments[weekdaynr]" />
        </div>
      </div>
      <div v-if="Object.keys(getDays()).length > 1">
        <label class="flix-html-label">{{ $t('message.use', {name: $tc('message.settings', 2), for: $tc('message.days')}) }}</label>
        <span v-for="(weekday, weekdaynr) in getDays()" :key="weekday + Math.random(0,1)">
          <a href="#" @click.prevent="function() { cloneSettings(weekdaynr) }" v-if="weekdaynr * 1 !== currentWeekday * 1" class="flix-btn flix-btn-default flix-btn-xs" :class="{'flix-btn-primary': cloneSetting.indexOf(weekdaynr) !== -1}">
            <flixIcon :id="'check'" v-if="cloneSetting.indexOf(weekdaynr) !== -1" />
            <flixIcon :id="'unchecked'" v-else />
            {{ $t('constants.weekdays')[weekdaynr] }}
          </a>
        </span>
      </div>
      </div>
      </transition>
      </div>
      <div class="flix-form-group" v-if="$store.getters.user && clickedServices">
        <a href="#" @click.prevent="openServices = !openServices" class="flix-html-h3 flix-html-a">{{ $t('message.add', { name: $tc('message.service', 1) }) }} <flixIcon v-if="!openServices" :id="'chevron-up'"/><flixIcon v-else :id="'chevron-down'"/></a>
      </div>
      <transition name="flixFadeIn">
      <div class="flix-form-group" v-if="openServices && $store.getters.user && clickedServices" :key="openServices">
        <serviceEditor />
      </div>
      </transition>
        <div class="flix-form-group">
          <hr class="flix-html-hr" />
          <saveBtn :onClick="function () { saveData() }"><template v-slot:text>{{ $t('message.save', {name: $tc('message.appointmentRequests', 2)}) }}</template></saveBtn>
        </div>
        <div class="flix-form-group">
          <flixIcon :id='"warning-sign"' /> {{ $t('message.excludePause') }}

    </div>
  </div>
</template>
<script>
export default {
  components: {
    serviceEditor () { return import('@/components/services/') },
    appointmentMarquee () { return import('@/components/assistent/components/datepicker/appointmentMarquee') }
  },
  props: { data: Object, callback: Function },
  data () {
    var selectTime = [{ title: this.$t('message.appointmentDuration') }]
    if (this.$store.getters.user) {
      selectTime.push({ title: this.$t('message.appointmentService') })
    }
    return {
      cloneSetting: [],
      selectTime: selectTime,
      next_appointments: this.getNextAppointments(),
      pickedTime: this.getPickedTimes(this.data.include.weekdays),
      updatePickedTime: new Date().getTime(),
      clickedServices: false,
      openServices: false,
      max_applicants: this.getMaxApplicants(),
      days: this.data.include.weekdays,
      currentWeekday: 0,
      updateKey: 0,
      marqueeKey: 0,
      select: {
        time: {
          60: '1 ' + this.$t('message.hour'),
          30: '30 ' + this.$t('message.minutes'),
          15: '15 ' + this.$t('message.minutes'),
          // 90: '90 ' + this.$t('message.minutes'),
          120: '2 ' + this.$t('message.hours'),
          180: '3 ' + this.$t('message.hours'),
          240: '4 ' + this.$t('message.hours'),
          300: '5 ' + this.$t('message.hours'),
          360: '6 ' + this.$t('message.hours'),
          420: '7 ' + this.$t('message.hours'),
          480: '8 ' + this.$t('message.hours')
        },
        services: this.getServices()
      }
    }
  },
  watch: {
    '$store.getters.serviceKey' () { this.select.services = this.getServices() }
  },
  methods: {
    checkLastAppointment (time) {
      time = time * 1
      if (time !== 15 && time !== 30 && time !== 60) {
        return true
      } else {
        return false
      }
    },
    setChangeAppointmentLength (val) {
      var days = this.getDays()

      if (val <= 60) {
        this.next_appointments[this.currentWeekday] = days[this.currentWeekday][2]
      }
    },
    setMarqueeKey () {
      this.marqueeKey = new Date().getTime()
    },
    getPause (weekdaynr) {
      if (typeof this.data.exclude.weekdays[weekdaynr] === 'undefined') {
        return []
      }
      return this.data.exclude.weekdays[weekdaynr]
    },
    cloneSettings (weekdaynr) {
      if (this.cloneSetting.indexOf(weekdaynr) === -1) {
        this.cloneSetting.push(weekdaynr)
      } else {
        Object.keys(this.cloneSetting).forEach(function (k) {
          if (this.cloneSetting[k] === weekdaynr) {
            this.cloneSetting.splice(k, 1)
          }
        }.bind(this))
      }
      this.updateKey = new Date().getTime()
    },
    setClone () {
      this.cloneSetting.forEach(function (weekdaynr) {
        this.currentWeekday = this.currentWeekday * 1
        this.max_applicants[weekdaynr] = this.max_applicants[this.currentWeekday]
        this.next_appointments[weekdaynr] = this.next_appointments[this.currentWeekday]
        this.days[weekdaynr][2] = this.days[this.currentWeekday][2]
        this.pickedTime[weekdaynr] = this.pickedTime[this.currentWeekday]
      }.bind(this))
    },
    getNextAppointments () {
      var r = {}
      if (typeof this.data.next_appointments === 'undefined') {
        this.data.next_appointments = {}
      }
      Object.keys(this.data.include.weekdays).forEach(function (day) {
        if (typeof this.data.next_appointments[day] === 'undefined') {
          r[day] = 60
        } else {
          r[day] = this.data.next_appointments[day]
        }
      }.bind(this))
      return r
    },
    setPickedTime (weekdaynr, value) {
      this.pickedTime[weekdaynr] = value.target.value * 1
      this.updatePickedTime = new Date().getTime()
      this.getCheckServices()
    },
    getCheckServices () {
      this.clickedServices = true
      if (Object.values(this.pickedTime).indexOf(1) === -1) {
        this.clickedServices = false
      }
    },
    getSelectTimeValue (weekday) {
      if (isNaN(weekday * 1)) {
        this.clickedServices = true
        return 1
      }
      return 0
    },
    getServices () {
      var r = {}
      if (!this.$store.getters.services || !Object.values(this.$store.getters.services).length) {
        return r
      }
      Object.values(this.$store.getters.services).forEach(function (s) {
        Object.values(s.short).forEach(function (b) {
          if (Object.values(r).indexOf(b) === -1) {
            r[b] = b
          }
        })
      })
      return r
    },
    saveData () {
      this.setClone()
      var data = { include: { weekdays: this.days, buffer: this.data.include.buffer, period: this.data.include.period }, max_applicants: this.max_applicants, next_appointments: this.next_appointments }
      this.callback(data)
    },
    getMaxApplicants () {
      var r = {}
      Object.keys(this.data.include.weekdays).forEach(function (day) {
        if (typeof this.data.max_applicants[day] === 'undefined') {
          r[day] = 1
        } else {
          r[day] = this.data.max_applicants[day]
        }
      }.bind(this))
      return r
    },
    getDays () {
      var r = {}
      Object.keys(this.days).forEach(function (k) {
        if (this.days[k].length) {
          r[k] = this.days[k]
        }
      }.bind(this))
      return r
    },
    getPickedTimes (days) {
      var r = {}
      Object.keys(days).forEach(function (k) {
        if (days[k].length) {
          r[k] = this.getSelectTimeValue(days[k][2])
        }
      }.bind(this))
      return r
    },
    setCloneSettings () {
      if (this.$getUserVariables().user !== false) {
        return false
      }
      Object.keys(this.getDays()).forEach(function (k) {
        if (k !== this.currentWeekday) {
          this.cloneSetting.push(k)
        }
      }.bind(this))
    }
  },
  mounted () {
    this.getCheckServices()
    this.currentWeekday = Object.keys(this.getDays())[0] * 1
    this.setCloneSettings()
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  @media (max-width: '500px')
    .flix-form-group
      font-size: 0.8em
    .flix-input-group-addon
      width: 100%
      display: block
</style>
